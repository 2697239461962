import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getLogger } from 'src/utils/datadog-logger';
import { tracker } from 'src/utils/analytics';

// FIXME: we are not suppose to import things form the internal of the analytics framework. we need to rework the trackerSource implementation to make is agnostic of GA
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';

import { GqlGetSponsoredBrandQuery, GqlAdTrackers } from 'types/graphql';
import { Product, parseProduct } from 'src/utils/helpers/product';
import { useFetchTracker } from 'src/utils/ads/hooks/use-fetch-tracker';
import { ProductPlacement } from 'src/types';

const logger = getLogger('ads.sponsored-brand');

type SponsoredBrandData = {
  campaignId: string | undefined;
  brandName: string | undefined;
  globalTrackers: GqlAdTrackers | undefined;
  products: Array<Product>;
};

export function useSponsoredBrandData(data: GqlGetSponsoredBrandQuery | null | undefined): SponsoredBrandData {
  const { name: brandName, campaignId, products = [], trackers: globalTrackers } = data?.getSponsoredBrand ?? {};

  const normalizedProducts = useMemo(() => products.map((item): Product => parseProduct(item)), [products]);

  return {
    brandName,
    campaignId,
    globalTrackers,
    products: normalizedProducts,
  };
}

type SponsoredBrandHandlersParams = SponsoredBrandData & {
  hasBeenVisible: boolean;
  placement: ProductPlacement;
};

type SponsoredBrandHandlers = {
  handleProductClick: (product: Product, index: number) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleProductImpression: (product: Product) => void;
  handleViewAllClick: () => void;
};

export function useSponsoredBrandHandlers({
  products = [],
  campaignId,
  globalTrackers,
  hasBeenVisible,
  placement,
}: SponsoredBrandHandlersParams): SponsoredBrandHandlers {
  const router = useRouter();
  const fetchTracker = useFetchTracker();

  const { impressionUrl, clickUrl } = globalTrackers ?? {};

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.moloco,
    section: ProductRecommendationSection.sponsoredBrand,
  });

  const handleBannerImpression = useCallback(() => {
    if (impressionUrl) {
      logger.info('handleBannerImpression', { impressionUrl, campaignId, trackerSource });

      void fetchTracker(impressionUrl);
      tracker.sponsoredBannerImpression({ impressionUrl, products, campaignId, trackerSource });
    }
  }, [campaignId, fetchTracker, impressionUrl, products, trackerSource]);

  const handleBannerClick = useCallback(() => {
    if (clickUrl) {
      logger.info('handleBannerClick', { clickUrl, campaignId, trackerSource });

      void fetchTracker(clickUrl);
      tracker.sponsoredBannerClicked({ clickUrl, products, campaignId, trackerSource });
    }
  }, [campaignId, fetchTracker, clickUrl, products, trackerSource]);

  // TODO: we should delegrate the lifecycle of the banner impression to the component that renders it
  useEffect(() => {
    if (hasBeenVisible) {
      handleBannerImpression();
    }
  }, [handleBannerImpression, hasBeenVisible]);

  // Sponsored Brand Product card view
  const handleProductImpression = useCallback(
    (product: Product) => {
      const { impressionUrl: productImpressionUrl } = product.adTrackers ?? {};

      if (productImpressionUrl) {
        logger.info('handleProductImpression', { impressionUrl: productImpressionUrl, campaignId, trackerSource });

        void fetchTracker(productImpressionUrl);
        tracker.sponsoredProductImpression({ product, campaignId, trackerSource });
      }
    },
    [fetchTracker, campaignId, trackerSource]
  );

  // Sponsored Brand Product card click
  const handleProductClick = useCallback(
    (product: Product, index: number): void => {
      handleBannerClick();

      const { clickUrl: productClickUrl } = product.adTrackers ?? {};

      if (productClickUrl) {
        logger.info('handleProductClick', { clickUrl: productClickUrl, campaignId, trackerSource });

        void fetchTracker(productClickUrl);
        tracker.sponsoredProductClicked({ product, campaignId, trackerSource, position: index });
        tracker.setContext({ activePromotion: { product, campaignId, trackerSource } });
      }

      // FIXME: why do we care about trackerSource in productClick?
      tracker.productClicked({ product, position: index, trackerSource, placement });
    },
    [handleBannerClick, trackerSource, placement, fetchTracker, campaignId]
  );

  return {
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart: handleProductClick,
    handleViewAllClick: handleBannerClick,
  };
}

export function useHasValidSponsoredBrandPlacement(target: 'category-page' | 'home-page'): boolean {
  const flags = useFlags();
  const config = flags['growth.ads.sponsored-brand-carousel.placement'];

  if (target === 'home-page') {
    return ['home', 'home-category'].includes(config);
  }

  return ['category', 'home-category'].includes(config);
}
